import React from 'react';
import styled from 'styled-components';

import { ContactForm } from 'components/Contact';
import { Banner, SEO } from 'components/UI';
import {
	DESKTOP_GRID_COLUMN,
	DESKTOP_GRID_SPACING,
	DESKTOP_GRID_SIZE,
	TABLET_GRID_SIZE
} from 'consts';
import quote from 'data/request-quote.json';
import seo from 'data/seo.json';
import { MediaQueries, Sizes } from 'environment';
import { useQueryContext } from 'hooks';

interface Props {
	location: {
		state?: {
			plan: string;
		};
	};
}

export default function RequestQuote({ location }: Props) {
	const { images } = useQueryContext();

	return (
		<Container>
			<SEO title={seo.quoteTitle} ogTitle={seo.quoteOgTitle} />
			{images.banners.contact && <Banner image={images.banners.contact} />}
			<Content>
				<Title>{quote.title}</Title>
				<Description>{quote.description}</Description>
				<ContactForm plan={location.state?.plan} />
			</Content>
		</Container>
	);
}

const Container = styled.section`
	display: flex;
	justify-content: center;
	width: 100%;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 4rem;
	margin-bottom: 12rem;

	@media ${MediaQueries.phone} {
		flex-direction: column;
		margin-top: 4rem;
		margin-bottom: 6rem;
		padding: 0 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
		flex-direction: column;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding: 0 2rem;
	}
`;

const Title = styled.h1`
	margin-bottom: 2rem;
	text-align: center;

	@media ${MediaQueries.phone} {
		font-family: 'Open Sans';
		font-size: 3.6rem;
		font-weight: 600;
		line-height: 5rem;
		margin-bottom: 1rem;
	}
`;

const Description = styled.p`
	margin-bottom: 12rem;
	text-align: center;

	@media ${MediaQueries.phone} {
		margin-bottom: 6rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${6 * DESKTOP_GRID_COLUMN + 5 * DESKTOP_GRID_SPACING}rem;
	}
`;
